.lista {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    text-align: justify;
    text-justify: inter-word;

}
.listliste {
    border: 1px solid #ddd; /* Add a thin border to each list item */
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6; /* Add a grey background color */
    padding: 12px; /* Add some padding */
    text-align: justify;
    text-justify: inter-word;

  
}
.canceledTransfer {
    background-color: red;
    margin-top: 2rem;
    color: white;
}
.completedTransfer {
    background-color: #20c997;
    margin-top: 2rem;
    color: white;

}
.activeTransfer {
    background-color: blue;
    margin-top: 2rem;
    color: white;

}
.nodecor {
    text-decoration: none;
    color: black;
    text-align: justify;
    text-justify: inter-word;
}
.bg-grey {
    background-color: #212529;
}
/* hr.rounded {
    border-top: 8px solid black;
    border-radius: 5px;
  } */

  div {
    text-align: justify;
    text-justify: inter-word;
    font-size:5vw;
    margin: 0.5rem;
  }
  .divider {
    height: 0.5rem;
    background: black;
    border-radius: 10px;
  }