.WelcomeContainer {
    height: 200px;
    position: relative;
    max-width: 500px;
    margin: auto;
  }
  
  .vertical-center {

    margin-top: 40%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 4rem;
  }